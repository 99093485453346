import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import vueuse_head_plugin_D7WGfuP1A0 from "/vercel/path0/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import router_PJLmOmdFeM from "/vercel/path0/node_modules/nuxt/dist/app/plugins/router.mjs";
import plugin_client_Fdvg3o8568 from "/vercel/path0/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import plugin_OcT8LIvbNC from "/vercel/path0/node_modules/nuxt-bugsnag/dist/runtime/plugin.mjs";
export default [
  components_plugin_KR1HBZs4kY,
  vueuse_head_plugin_D7WGfuP1A0,
  router_PJLmOmdFeM,
  plugin_client_Fdvg3o8568,
  plugin_OcT8LIvbNC
]